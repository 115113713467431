import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { format } from "date-fns";

const Card7 = ({ data }) => {
  const truncate = (str, max, suffix) => {
    // ReactHtmlParser(str);
    return str?.length < max ? str : `${str?.substr(0, str?.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  };

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div className="card card7 is-flex has-text-left	">
      <div className="card-left">
        <Link to={`/${data[0]?.slug}`}>
          {data[0]?.image.includes("idc") ? <img src={addStr(data[0]?.image, 65, "400x400_")} alt={data[0]?.title} /> : <img src={addStr(data[0]?.image, 37, "400x400_")} alt={data[0]?.title} />}
        </Link>
        {data[0]?.categories[0]?.name && (
          <span is-uppercase className="tag">
            {data[0]?.categories[0]?.name}
          </span>
        )}
      </div>
      <div className="content">
        <div className="post-meta">
          <Link to={`/${data[0]?.slug}`}>
            <p className="title is-4 as-text-left">{data[0]?.title}</p>
            {data[0]?.pub_date && <p className="author mt-0 is-italic">{format(new Date(data[0]?.pub_date), "EEEE, MMMM yyyy")}</p>}
            <p className="subtitle mt-4">{truncate(data[0]?.content?.replace(/<[^>]+>/g, ""), 150, "...")}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card7;
