import React from "react";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Card9 from "../../../../components/blocks/card9/card9";
import Separator2 from "../../../../components/separators/separator2";

const Sec1 = ({ data, separator }) => {
  const card_1 = data ? [data[0]] : [];
  const card_2 = data ? [data[1]] : [];
  const card_3 = data ? [data[2]] : [];

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
  };
  return (
    <div className="section-seven">
      <div>
        {/* <Card9 data={data} /> */}
        <div className="container is-max-widescreen mt-2 mb-2 ">
          <Slider {...settings}>
            <Card9 data={card_1} />
            <Card9 data={card_2} />
            <Card9 data={card_3} />
          </Slider>
          <Separator2 text={separator} />
        </div>
      </div>
    </div>
  );
};

export default Sec1;
