import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { format } from "date-fns";

const Card2 = ({ data }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div
      className="card card2"
      style={{
        backgroundImage: `url(${data[0]?.image.includes("idc") ? addStr(data[0]?.image, 65, "400x400_") : addStr(data[0]?.image, 37, "400x400_")})`,
      }}
    >
      <Link to={`/${data[0]?.slug}`}>
        <div className="bottom">
          <div className="post-meta">
            {data[0]?.categories[0]?.name && <span className="category">{data[0]?.categories[0]?.name}</span>}
            <h3 className="title mb-1"></h3>
            {/* <span className="author">Kaylee Garcia - </span> */}
            {data[0]?.pub_date && <span className="date">{format(new Date(data[0]?.pub_date), "EEEE, MMMM yyyy")}</span>}
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Card2;
