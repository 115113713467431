import React from "react";
import "./style.scss";
import Card7 from "../../../../components/blocks/card7/card7";
import Separator2 from "../../../../components/separators/separator2";
import Card2 from "../../../../components/blocks/card2/card2";

const Sec3 = ({ data, separator }) => {
  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);

  const card_1 = temp ? [temp[11]] : [];
  const card_2 = temp ? [temp[12]] : [];
  const card_3 = temp ? [temp[13]] : [];

  return (
    <div className="section-three">
      {/* <pre>{JSON.stringify({ card2 }, null, 2)}</pre> */}
      <div className="container is-max-widescreen mt-2">
        <div className="columns is-tablet mr-0 ml-0">
          {/* <div className="columns is-mobile mt-2"> */}
          <div className="column is-7">
            <Card7 data={card_1} />
            <hr />
            <Card7 data={card_2} />
          </div>
          <div className="column is-5">
            <Card2 data={card_3} />
          </div>
          {/* </div> */}
        </div>
        <Separator2 text={separator} />
      </div>
    </div>
  );
};
export default Sec3;
