import React from "react";
import "../pages/mystyles.scss";
import "./cutils.scss";
//import Header from "../components/header/header";
import Header from "../../../../components/header/Header";
import Sec1 from "../../../../components/home/components/sec1/sec1";
import Sec3 from "../../../../components/home/components/sec3/sec3";
import Sec2 from "../../../../components/home/components/sec2/sec2";
import Sec6 from "../../../../components/home/components/sec6/sec6";
import List2 from "../../../../components/home/components/list/List2";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import banner from "../images/banner.png";
import banner1 from "../images/banner1.png";
import gif from "../images/booking.png";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  const sidebar = {
    banner,
    banner1,
    gif,
    url: "https://www.a88wptk.com/KHNZB/2N9RR47/",
  };

  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);
  let hiro_data = temp?.filter(checkFetured);
  function checkFetured(element) {
    return element?.is_feature_post;
  }
  return (
    <div className="layout">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
      </Helmet>
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} />
      <Sec1 data={hiro_data} separator="Popular Articles" />
      <Sec2 data={data} separator="Popular Listicles" />
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Sec3 data={data} separator="Editor's Picks" />
      <Sec6 data={data} />
      <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List2 data={data?.cms?.recent_story} />
          </div>
          <div className="column">
            <Sidebar recent_story={hiro_data} banner={sidebar} />
          </div>
        </div>
      </div>
      <div className="banner-container">
        <Link to={sidebar?.url}>
          <img src={sidebar?.banner1} alt={"banner"} />
        </Link>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={": top10travelsites@club"} />
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query home {
    cms {
      domain: CMS_Domain(id: "21") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "21") {
        name
        slug
      }
      tech_listicle: CMS_DomainWiseLists(domain_id: 21) {
        title
        image
        slug
        categories {
          name
        }
      }
      tech_story: CMS_DomainWiseStories(domain_id: "21") {
        image
        slug
        title
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "21") {
        title
        is_feature_post
        image
        slug
        description
        pub_date
        categories {
          name
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "21") {
        image
        slug
        title
        content
        is_feature_post
        pub_date
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
